import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import PropTypes from "prop-types"
import Layout from "../../components/shared/layout"
import SEO from "../../components/SEO/SEO"
import styled from "@emotion/styled"
import {
  wrapper,
  wrapper1200,
  featuredWrapper,
  noFeaturedWrapper,
  breakpoints,
  ContentStyles,
  ContentStylesExt,
} from "../../utils/style"
import Parallax from "../../components/shared/Parallax"
import Breadcrumbs from "../../components/shared/Breadcrumbs"

const WrapperDesktop = styled("div")`
  ${wrapper1200}
`

const FeaturedWrapper = styled("div")`
  ${featuredWrapper}
  position: relative;
  overflow: hidden;
  border-radius: 24px;
  .gatsby-image-wrapper {
    border-radius: 24px;
  }
  @media (max-width: ${breakpoints.md}) {
    border-radius: 10px;
    .gatsby-image-wrapper {
      border-radius: 10px;
    }
    .img-wrap {
      .gatsby-image-wrapper {
        border-radius: 10px;
      }
    }
  }
`

const NoFeaturedWrapper = styled("div")`
  ${noFeaturedWrapper}
`

const ContentWrapper = styled("div")`
  ${wrapper}
  ${ContentStyles}
  ${ContentStylesExt}
  .prev-next {
    min-height: 70px;
    padding-bottom: 20px;
  }
  .next-button {
    float: right;
  }
`

const PostTemplate = ({ data, pageContext }) => {
  const post = data.wordpressPost
  const metaTitle =
    post.yoast_meta && post.yoast_meta.yoast_wpseo_title
      ? post.yoast_meta.yoast_wpseo_title
      : post.title + +"- " + process.env.OWNER
  const metaDesc =
    post.yoast_meta && post.yoast_meta.yoast_wpseo_metadesc
      ? post.yoast_meta.yoast_wpseo_metadesc
      : ""
  const breadcrumbs = pageContext.breadcrumbs
  const page_title = pageContext.page_title
  const socialImage = post.featured_media?.localFile?.publicURL

  return (
    <Layout>
      <SEO
        title={metaTitle}
        description={metaDesc}
        pathname={post.path}
        key="seo"
        breadcrumbs={breadcrumbs}
        socialImage={socialImage}
      />
      <Parallax />
      <WrapperDesktop>
        <Breadcrumbs breadcrumbs={breadcrumbs} page_title={page_title} />
        {post.featured_media &&
        post.featured_media.localFile &&
        post.featured_media.localFile.childImageSharp &&
        post.featured_media.localFile.childImageSharp.fluid ? (
          <FeaturedWrapper key="featured-wrapper">
            <Img
              fluid={post.featured_media.localFile.childImageSharp.fluid}
              alt={post.featured_media.alt_text}
            />
            <h1 dangerouslySetInnerHTML={{ __html: post.title }} />
          </FeaturedWrapper>
        ) : (
          <NoFeaturedWrapper key="no-featured-wrapper">
            <h1 dangerouslySetInnerHTML={{ __html: post.title }} />
          </NoFeaturedWrapper>
        )}

        <ContentWrapper key="content-wrapper">
          <div
            key="content"
            dangerouslySetInnerHTML={{ __html: post.content }}
          />
        </ContentWrapper>
      </WrapperDesktop>
    </Layout>
  )
}

PostTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array,
}

export default PostTemplate

export const pageQuery = graphql`
  query($id: String!) {
    wordpressPost(id: { eq: $id }) {
      wordpress_id
      title
      content
      path
      date(formatString: "MMMM DD, YYYY")
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
      featured_media {
        alt_text
        localFile {
          publicURL
          childImageSharp {
            fluid(maxWidth: 1400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`
